import { Button, Divider, Group, Stack } from "@mantine/core";
import { IconBrandGoogle, IconBrandX } from "@tabler/icons-react";
import { Form } from "@remix-run/react";
import { getRoute } from "~/routes";
import { useTranslation } from "react-i18next";

export function SocialLoginButtons() {
  const { t } = useTranslation();

  return (
    <div>
      <Stack mb="md">
        <Form method="POST" action={getRoute("authWithGoogle")}>
          <Button
            leftSection={<IconBrandGoogle size={18} />}
            variant="default"
            color="gray"
            type="submit"
            fullWidth
          >
            {t("continue_with_google")}
          </Button>
        </Form>
        <Form method="POST" action={getRoute("authWithX")}>
          <Button
            leftSection={<IconBrandX size={18} />}
            variant="default"
            color="gray"
            type="submit"
            fullWidth
          >
            {t("continue_with_x")}
          </Button>
        </Form>
      </Stack>
      <Divider mt={"xl"} mb={"lg"}></Divider>
    </div>
  );
}
